export const inquiryModeResponses = [
  {
    text: 'Present evidence',
    openInventoryForInquiry: true,
  },
  {
    text: 'Thank you, bye-bye!',
    switchToFarewellDialogue: true,
  },
];
