const notes = [
  {
    descriptionA: [
      {
        _key: 'fda2a4586e1c',
        _type: 'block',
        children: [
          {
            _key: '9959f034edbc0',
            _type: 'span',
            marks: ['em'],
            text: 'A lion that owns a castle.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionB: [
      {
        _key: '51a3454ee3e3',
        _type: 'block',
        children: [
          {
            _key: 'f070352f558b',
            _type: 'span',
            marks: ['em'],
            text: 'A “king” who enjoys watching things and knowing what time it is.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '949b0de6865f',
        _type: 'block',
        children: [
          {
            _key: 'e9432c63a144',
            _type: 'span',
            marks: [],
            text: 'Stargazing last night',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'cf4a24149b06',
        _type: 'block',
        children: [
          {
            _key: '3a8c43961a2a',
            _type: 'span',
            marks: [],
            text: 'Claims',
          },
          {
            _key: 'ac531549ad29',
            _type: 'span',
            marks: ['strong'],
            text: ' ',
          },
          {
            _key: 'f532a96506a4',
            _type: 'span',
            marks: ['em'],
            text: 'Ñenn ',
          },
          {
            _key: '381f8d889071',
            _type: 'span',
            marks: [],
            text: 'didn’t move last night.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '5e7209ea0aa8',
        _type: 'block',
        children: [
          {
            _key: '7682645efb3d',
            _type: 'span',
            marks: [],
            text: 'Saw',
          },
          {
            _key: 'd06f0e37fb00',
            _type: 'span',
            marks: ['em'],
            text: ' Lucky',
          },
          {
            _key: '6d9ba173dc9f',
            _type: 'span',
            marks: [],
            text: ' leave his house at ',
          },
          {
            _key: '3ab63fa9f0e8',
            _type: 'span',
            marks: ['em'],
            text: '9:52PM',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '83ea213676d7',
        _type: 'block',
        children: [
          {
            _key: 'b005c6d03b9c',
            _type: 'span',
            marks: [],
            text: 'Claims crime took place between ',
          },
          {
            _key: '6d816c137f77',
            _type: 'span',
            marks: ['em'],
            text: '10 and 10:10PM',
          },
          {
            _key: '5d616f3235ae',
            _type: 'span',
            marks: [],
            text: ', during his tea time break.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'b7dc06723072',
        _type: 'block',
        children: [
          {
            _key: '7d494ccfc30a',
            _type: 'span',
            marks: [],
            text: 'Said ',
          },
          {
            _key: '257b26db0f50',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: '8fdef1e2877e',
            _type: 'span',
            marks: [],
            text: ' was brought to the infirmary by',
          },
          {
            _key: 'dd2cbe681421',
            _type: 'span',
            marks: ['strong'],
            text: ' ',
          },
          {
            _key: 'f8a19c48a502',
            _type: 'span',
            marks: ['em'],
            text: 'Ñenn ',
          },
          {
            _key: '6d8293bbc7c1',
            _type: 'span',
            marks: [],
            text: '& ',
          },
          {
            _key: 'd265c47e4e86',
            _type: 'span',
            marks: ['em'],
            text: 'Sterling',
          },
          {
            _key: 'eb538c672b84',
            _type: 'span',
            marks: [],
            text: ' at',
          },
          {
            _key: 'e92ae7272f87',
            _type: 'span',
            marks: ['em'],
            text: ' 2:12AM.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionC: [
      {
        _key: 'bfa0b599ea82',
        _type: 'block',
        children: [
          {
            _key: 'c07df1f3d8f2',
            _type: 'span',
            marks: ['em'],
            text: 'A “king” who enjoys spying and knowing what time it',
          },
          {
            _key: 'cb0d15a34bdc',
            _type: 'span',
            marks: ['em'],
            text: ' is.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '91854748e82a',
        _type: 'block',
        children: [
          {
            _key: 'd77f0d9c32340',
            _type: 'span',
            marks: ['strong', 'em'],
            text: 'Innocent',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'e2fd1108f326',
        _type: 'block',
        children: [
          {
            _key: '501ac3bc8dcf',
            _type: 'span',
            marks: [],
            text: 'People watched last night.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'b15888a78f66',
        _type: 'block',
        children: [
          {
            _key: 'cfbc782affd7',
            _type: 'span',
            marks: [],
            text: 'Has video proof that ',
          },
          {
            _key: '6054a6deab11',
            _type: 'span',
            marks: ['em'],
            text: 'Ñenn',
          },
          {
            _key: '925dd0f96af9',
            _type: 'span',
            marks: [],
            text: ' didn’t move last night.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '68398dc62bad',
        _type: 'block',
        children: [
          {
            _key: 'bacc2c35c774',
            _type: 'span',
            marks: [],
            text: 'Saw ',
          },
          {
            _key: '9db6c6c1e405',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: '5e72f9edf7e7',
            _type: 'span',
            marks: [],
            text: ' leave his house at ',
          },
          {
            _key: '8976a0808793',
            _type: 'span',
            marks: ['em'],
            text: '9:52PM',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '3b23d27e5211',
        _type: 'block',
        children: [
          {
            _key: '465c1ec35abf',
            _type: 'span',
            marks: [],
            text: 'Claims the crime took place between ',
          },
          {
            _key: 'e4d0b0705104',
            _type: 'span',
            marks: ['em'],
            text: '10 and 10:10PM',
          },
          {
            _key: '71cb3aff5336',
            _type: 'span',
            marks: [],
            text: '.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '4cfe02fd2760',
        _type: 'block',
        children: [
          {
            _key: 'a94aeb075238',
            _type: 'span',
            marks: [],
            text: 'Said ',
          },
          {
            _key: '2ea0160889b4',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: 'a73a3efd515f',
            _type: 'span',
            marks: [],
            text: ' was brought to the infirmary by ',
          },
          {
            _key: '619766846735',
            _type: 'span',
            marks: ['em'],
            text: 'Ñenn',
          },
          {
            _key: 'df0045d2d5da',
            _type: 'span',
            marks: ['em'],
            text: ' ',
          },
          {
            _key: '063cc3fa87f3',
            _type: 'span',
            marks: [],
            text: '& ',
          },
          {
            _key: 'd224a21638d5',
            _type: 'span',
            marks: ['em'],
            text: 'Sterling',
          },
          {
            _key: 'c7dc0d4236c2',
            _type: 'span',
            marks: [],
            text: ' at',
          },
          {
            _key: '59c0ae440973',
            _type: 'span',
            marks: ['em'],
            text: ' 2:12A',
          },
          {
            _key: '08d57f6b6cfd',
            _type: 'span',
            marks: ['em'],
            text: 'M.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionD: [
      {
        _key: '873c48822a66',
        _type: 'block',
        children: [
          {
            _key: 'c8dd11586ff2',
            _type: 'span',
            marks: ['em'],
            text: 'A “king” who enjoys spying and knowing what time it is.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'd9a828b30064',
        _type: 'block',
        children: [
          {
            _key: 'fcd4e28162b1',
            _type: 'span',
            marks: ['strong', 'em'],
            text: 'Innocent',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
      },
      {
        _key: '420a2709098a',
        _type: 'block',
        children: [
          {
            _key: '317422860ecc',
            _type: 'span',
            marks: [],
            text: 'People watched last night.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '9f92c8e5be5f',
        _type: 'block',
        children: [
          {
            _key: '41117dffbcca',
            _type: 'span',
            marks: [],
            text: 'Has video proof that ',
          },
          {
            _key: '2afb1ba31779',
            _type: 'span',
            marks: ['em'],
            text: 'Ñenn',
          },
          {
            _key: 'b9596a176b4b',
            _type: 'span',
            marks: [],
            text: ' didn’t move last night.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '1f005ce33664',
        _type: 'block',
        children: [
          {
            _key: 'f10093e18ec9',
            _type: 'span',
            marks: [],
            text: 'Saw ',
          },
          {
            _key: '13a1e776072f',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: '7c76db79db25',
            _type: 'span',
            marks: [],
            text: ' leave his house at ',
          },
          {
            _key: '11870ecf8340',
            _type: 'span',
            marks: ['em'],
            text: '9:52PM',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'ed5fb94d30ed',
        _type: 'block',
        children: [
          {
            _key: 'bffb5428e0a6',
            _type: 'span',
            marks: [],
            text: 'Claims the crime took place between ',
          },
          {
            _key: '0536e07dcda9',
            _type: 'span',
            marks: ['em'],
            text: '10 and 10:10PM',
          },
          {
            _key: '7bd192762e62',
            _type: 'span',
            marks: [],
            text: '.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'b6ac26ca312c',
        _type: 'block',
        children: [
          {
            _key: '63524dc6551b',
            _type: 'span',
            marks: [],
            text: 'Said ',
          },
          {
            _key: '99dd9868a7a2',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: 'bb2327d43e1e',
            _type: 'span',
            marks: [],
            text: ' was brought to the infirmary by ',
          },
          {
            _key: '123af57c22df',
            _type: 'span',
            marks: ['em'],
            text: 'Ñenn ',
          },
          {
            _key: '0d25d6f09a29',
            _type: 'span',
            marks: [],
            text: '& ',
          },
          {
            _key: 'b56dcced1c23',
            _type: 'span',
            marks: ['em'],
            text: 'Sterling',
          },
          {
            _key: '4cd5965ebc2b',
            _type: 'span',
            marks: [],
            text: ' at',
          },
          {
            _key: '209d9fd5a628',
            _type: 'span',
            marks: ['em'],
            text: ' 2:12AM.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    imageUrl:
      'https://cdn.sanity.io/images/qvonp967/production/ccc47c0c5266c18f970b75f56d3fdb9f5d345846-128x128.png',
    name: 'Elvis',
  },
  {
    descriptionA: [
      {
        _key: 'b36dd9267349',
        _type: 'block',
        children: [
          {
            _key: '569b8e043f390',
            _type: 'span',
            marks: ['em'],
            text: 'The castle guard.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionB: [
      {
        _key: '7a5a28e72935',
        _type: 'block',
        children: [
          {
            _key: '4d055e93f3cd',
            _type: 'span',
            marks: ['em'],
            text: 'The castle guard.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '03d3dcd64da0',
        _type: 'block',
        children: [
          {
            _key: '6fd769392418',
            _type: 'span',
            marks: [],
            text: 'Had a couple of skirmishes with a “shadowy demon” last night. He threw a protein shake at it.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
      },
      {
        _key: 'd5c337a17f45',
        _type: 'block',
        children: [
          {
            _key: 'ba3bb5d959c4',
            _type: 'span',
            marks: [],
            text: 'Is scared of ',
          },
          {
            _key: 'aae64c38537d',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: '844a148a8c35',
            _type: 'span',
            marks: [],
            text: '.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '63cc815cd2b1',
        _type: 'block',
        children: [
          {
            _key: '431fe84a0aa0',
            _type: 'span',
            marks: ['em'],
            text: 'Ñenn',
          },
          {
            _key: '233dcc32fab6',
            _type: 'span',
            marks: [],
            text: ' got ',
          },
          {
            _key: '097b9cf308cf',
            _type: 'span',
            marks: ['em'],
            text: 'Sterling',
          },
          {
            _key: '9b998d1c646f',
            _type: 'span',
            marks: [],
            text: ' to help them carry ',
          },
          {
            _key: 'a5f95304d5e4',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: 'da369d1a86c5',
            _type: 'span',
            marks: [],
            text: ' to the infirmary.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'd018a1dc9876',
        _type: 'block',
        children: [
          {
            _key: 'b322110e3db3',
            _type: 'span',
            marks: [],
            text: 'Saw ',
          },
          {
            _key: 'a99a382b5b6e',
            _type: 'span',
            marks: ['em'],
            text: 'Stitches',
          },
          {
            _key: '7808cd394630',
            _type: 'span',
            marks: [],
            text: ' come into the infirmary to give ',
          },
          {
            _key: '6bd051d2b9c2',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: 'a671eba70713',
            _type: 'span',
            marks: [],
            text: ' stitches.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'db3e2d8f8daf',
        _type: 'block',
        children: [
          {
            _key: '55f875ac1ed2',
            _type: 'span',
            marks: [],
            text: 'Blames the shadowy demon, which he thinks is ',
          },
          {
            _key: '340105091808',
            _type: 'span',
            marks: ['em'],
            text: 'Ñenn',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '5cfb6c17df93',
        _type: 'block',
        children: [
          {
            _key: '478e373d8743',
            _type: 'span',
            marks: [],
            text: 'Really wants me to say “Throw ‘em into the dungeon!”',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '14fb716c08af',
        _type: 'block',
        children: [
          {
            _key: '109a798afab1',
            _type: 'span',
            marks: [],
            text: 'Has a dungeon.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionC: [
      {
        _key: '6bda9b378640',
        _type: 'block',
        children: [
          {
            _key: 'cd62aed1f917',
            _type: 'span',
            marks: [],
            text: 'The castle guard.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'f119225712ef',
        _type: 'block',
        children: [
          {
            _key: '3cf6622bce4b',
            _type: 'span',
            marks: ['strong', 'em'],
            text: 'Innocent',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'e70ad928bf80',
        _type: 'block',
        children: [
          {
            _key: '2d22fc0087a1',
            _type: 'span',
            marks: [],
            text: 'Had tea time with ',
          },
          {
            _key: '83dc49e412e1',
            _type: 'span',
            marks: ['em'],
            text: 'Elvis',
          },
          {
            _key: 'b821324d7594',
            _type: 'span',
            marks: [],
            text: ' during the window the crime took place.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '85c2535cb91f',
        _type: 'block',
        children: [
          {
            _key: '5bdd73657866',
            _type: 'span',
            marks: [],
            text: 'Had a couple of skirmishes with a “shadowy demon” last night. He threw a protein shake at it.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '345c873d1479',
        _type: 'block',
        children: [
          {
            _key: '116df659bc54',
            _type: 'span',
            marks: [],
            text: 'Helped carry ',
          },
          {
            _key: '3b02fbd3ba96',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: '3a9de72c0447',
            _type: 'span',
            marks: [],
            text: ' t',
          },
          {
            _key: 'f76f3ad2c433',
            _type: 'span',
            marks: [],
            text: 'o the infirmary soon after',
          },
          {
            _key: '9712b14885b9',
            _type: 'span',
            marks: ['em'],
            text: ' 2AM',
          },
          {
            _key: '2ccc78bb8a5e',
            _type: 'span',
            marks: [],
            text: '.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '972b9b557a37',
        _type: 'block',
        children: [
          {
            _key: '87488eb4a68f',
            _type: 'span',
            marks: [],
            text: 'Really wants me to say “Throw ‘em into the dungeon!”',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '496fb4cdde54',
        _type: 'block',
        children: [
          {
            _key: '3e773fe8b6ad',
            _type: 'span',
            marks: [],
            text: 'Has a dungeon.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionD: [
      {
        _key: 'cd48db0fbcf5',
        _type: 'block',
        children: [
          {
            _key: '0ab25ae969bf',
            _type: 'span',
            marks: [],
            text: 'The castle guard.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'd277d7ba6f37',
        _type: 'block',
        children: [
          {
            _key: '8339b7d1032e',
            _type: 'span',
            marks: ['strong', 'em'],
            text: 'Innocent',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
      },
      {
        _key: 'a52f406278f6',
        _type: 'block',
        children: [
          {
            _key: '12d73ce1f261',
            _type: 'span',
            marks: [],
            text: 'Had tea time with ',
          },
          {
            _key: '0f6d96017cde',
            _type: 'span',
            marks: ['em'],
            text: 'Elvis',
          },
          {
            _key: '0d0601b2c6ff',
            _type: 'span',
            marks: [],
            text: ' during the window the crime took place.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '528ed9d3db9b',
        _type: 'block',
        children: [
          {
            _key: '797503c7f709',
            _type: 'span',
            marks: [],
            text: 'Had skirmishes with ',
          },
          {
            _key: '03f3d05d3f6f',
            _type: 'span',
            marks: ['em'],
            text: 'Katt',
          },
          {
            _key: '43e77b2dac21',
            _type: 'span',
            marks: [],
            text: ' and ',
          },
          {
            _key: 'c8b4f4159acc',
            _type: 'span',
            marks: ['em'],
            text: 'Ankha',
          },
          {
            _key: 'bc763622f3b2',
            _type: 'span',
            marks: [],
            text: ' last night. He threw a protein shake at ',
          },
          {
            _key: 'f6b2907bcaf8',
            _type: 'span',
            marks: ['em'],
            text: 'Ankha',
          },
          {
            _key: '4d51cb6b5b9c',
            _type: 'span',
            marks: [],
            text: '.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '4c88dc9016bf',
        _type: 'block',
        children: [
          {
            _key: '95bc34c205d5',
            _type: 'span',
            marks: [],
            text: 'Helped carry ',
          },
          {
            _key: '554d91f5ec3e',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: 'fa441756256e',
            _type: 'span',
            marks: [],
            text: ' to the infirmary soon after',
          },
          {
            _key: 'ffce9d0c5d0e',
            _type: 'span',
            marks: ['em'],
            text: ' 2AM',
          },
          {
            _key: '0e22e652f1eb',
            _type: 'span',
            marks: [],
            text: '.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'e73df4203068',
        _type: 'block',
        children: [
          {
            _key: 'da649361047c',
            _type: 'span',
            marks: [],
            text: 'Really wants me to say “Throw ‘em into the dungeon!”',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '730dff4525d7',
        _type: 'block',
        children: [
          {
            _key: '616f6a1ecc85',
            _type: 'span',
            marks: [],
            text: 'Has a dungeon.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    imageUrl:
      'https://cdn.sanity.io/images/qvonp967/production/4395ea7500e16861854911d8b533d425fcb877f0-128x128.png',
    name: 'Sterling',
  },
  {
    animalRef: {
      color: {
        _type: 'color',
        alpha: 1,
        hex: '#a76dee',
        hsl: {
          _type: 'hslaColor',
          a: 1,
          h: 267.085693359375,
          l: 0.6809000000000001,
          s: 0.7904000000000004,
        },
        hsv: {
          _type: 'hsvaColor',
          a: 1,
          h: 267.085693359375,
          s: 0.5405897380631859,
          v: 0.9331166400000002,
        },
        rgb: {
          _type: 'rgbaColor',
          a: 1,
          b: 238,
          g: 109,
          r: 167,
        },
      },
    },
    descriptionA: [
      {
        _key: '18f113ee5a05',
        _type: 'block',
        children: [
          {
            _key: '3802ecae7a6d0',
            _type: 'span',
            marks: ['em'],
            text: 'My client who is accused of almost murdering the victim.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'd30a0677daf6',
        _type: 'block',
        children: [
          {
            _key: '1ebb123884be',
            _type: 'span',
            marks: [],
            text: 'Was wishing on stars the whole night.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'c87ce47d777e',
        _type: 'block',
        children: [
          {
            _key: '24078ac6a9500',
            _type: 'span',
            marks: [],
            text: 'Found the victim, ',
          },
          {
            _key: '2537c5cdae47',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: 'e13a5138296d',
            _type: 'span',
            marks: [],
            text: ', at ',
          },
          {
            _key: 'ade0ebf61006',
            _type: 'span',
            marks: ['em'],
            text: '2AM ish',
          },
          {
            _key: '54b0bd81fe9d',
            _type: 'span',
            marks: [],
            text: '.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '8e9eed39b645',
        _type: 'block',
        children: [
          {
            _key: '9426dda880d00',
            _type: 'span',
            marks: [],
            text: 'Got ',
          },
          {
            _key: 'dd26dae58ac0',
            _type: 'span',
            marks: ['em'],
            text: 'Sterling',
          },
          {
            _key: '871591c3710a',
            _type: 'span',
            marks: [],
            text: ' to help carry ',
          },
          {
            _key: '768f23922b93',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: 'a732a116e28c',
            _type: 'span',
            marks: [],
            text: ' to the infirmary.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '7c3918d5a0c1',
        _type: 'block',
        children: [
          {
            _key: '877d3e9789fa0',
            _type: 'span',
            marks: [],
            text: 'Followed ',
          },
          {
            _key: '7425cd24986e',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: 'c434cc91887c',
            _type: 'span',
            marks: ['em'],
            text: '’s',
          },
          {
            _key: 'eb32bd71115d',
            _type: 'span',
            marks: [],
            text: ' request to retrieve ',
          },
          {
            _key: '6e9a30c4a80b',
            _type: 'span',
            marks: ['em'],
            text: 'Stitches',
          },
          {
            _key: '9c471849705b',
            _type: 'span',
            marks: [],
            text: ' so ',
          },
          {
            _key: 'f5419f4a79eb',
            _type: 'span',
            marks: ['em'],
            text: 'Stitches',
          },
          {
            _key: 'ac3232e12fa0',
            _type: 'span',
            marks: [],
            text: ' could give ',
          },
          {
            _key: '6bfaaf6ea3c1',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: 'c46f27df7a92',
            _type: 'span',
            marks: [],
            text: ' stitches.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '33fcb6cfa6f2',
        _type: 'block',
        children: [
          {
            _key: 'be8221dc2c7b0',
            _type: 'span',
            marks: [],
            text: 'Is considered suspicious by the others because they found the body and they’re kinda weird.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '47d36c80f567',
        _type: 'block',
        children: [
          {
            _key: '7a5e7f0ba4fe',
            _type: 'span',
            marks: [],
            text: 'Doesn’t know what happened but is convinced it wasn’t an accident.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '2462149558a7',
        _type: 'block',
        children: [
          {
            _key: '5f1d52ecdce8',
            _type: 'span',
            marks: [],
            text: 'They gave me a snazzy bag.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionB: [
      {
        _key: '1ab8dbe63b39',
        _type: 'block',
        children: [
          {
            _key: 'c59019c7861e',
            _type: 'span',
            marks: ['em'],
            text: 'My client who is accused of almost murdering the victim.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '3f83caf7b61c',
        _type: 'block',
        children: [
          {
            _key: 'aa7074341031',
            _type: 'span',
            marks: [],
            text: 'Was wishing on stars the whole night.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '63fa482b542a',
        _type: 'block',
        children: [
          {
            _key: '7e10ea24e3f1',
            _type: 'span',
            marks: [],
            text: 'Found the victim, ',
          },
          {
            _key: '9ed624c00700',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: '92c00d83a78b',
            _type: 'span',
            marks: [],
            text: ', at ',
          },
          {
            _key: 'f54d75a2eafe',
            _type: 'span',
            marks: ['em'],
            text: '2AM ish',
          },
          {
            _key: 'be2cee1986bb',
            _type: 'span',
            marks: [],
            text: '.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '602d22b85797',
        _type: 'block',
        children: [
          {
            _key: '350d3be09a01',
            _type: 'span',
            marks: [],
            text: 'Got ',
          },
          {
            _key: 'ee849f1e81b7',
            _type: 'span',
            marks: ['em'],
            text: 'Sterling',
          },
          {
            _key: '43e1c5099e71',
            _type: 'span',
            marks: [],
            text: ' to help carry ',
          },
          {
            _key: 'ffd3ebc2f045',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: 'b4aa1d723a9e',
            _type: 'span',
            marks: [],
            text: ' to the infirmary.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '0fbeba970bd8',
        _type: 'block',
        children: [
          {
            _key: 'df8a53d5b0c0',
            _type: 'span',
            marks: [],
            text: 'Followed ',
          },
          {
            _key: 'b1416aae9135',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky’s',
          },
          {
            _key: 'ac4520854ca9',
            _type: 'span',
            marks: [],
            text: ' request to retrieve ',
          },
          {
            _key: '872a7309a4bc',
            _type: 'span',
            marks: ['em'],
            text: 'Stitches',
          },
          {
            _key: '746d49659397',
            _type: 'span',
            marks: [],
            text: ' so ',
          },
          {
            _key: 'cc4f2e90094f',
            _type: 'span',
            marks: ['em'],
            text: 'Stitches',
          },
          {
            _key: '1f24d8f07c42',
            _type: 'span',
            marks: [],
            text: ' could give ',
          },
          {
            _key: '434d77d6a107',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: 'bc7268bf85a2',
            _type: 'span',
            marks: [],
            text: ' stitches.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '7cf2c669a4ed',
        _type: 'block',
        children: [
          {
            _key: 'fca340f84f23',
            _type: 'span',
            marks: [],
            text: 'Is considered suspicious by the others because they found the body and they’re kinda weird.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '51fd6995465c',
        _type: 'block',
        children: [
          {
            _key: 'ece9867d2cb5',
            _type: 'span',
            marks: [],
            text: 'Doesn’t know what happened but is convinced it wasn’t an accident.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'f689db3645b3',
        _type: 'block',
        children: [
          {
            _key: 'a2a62db2e50c',
            _type: 'span',
            marks: [],
            text: 'They gave me a snazzy bag.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionC: [
      {
        _key: 'bb5f474bc0b0',
        _type: 'block',
        children: [
          {
            _key: '70bb499b9ca50',
            _type: 'span',
            marks: ['em'],
            text: 'My client, whose name I successfully cleared.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'e24f1d3a152a',
        _type: 'block',
        children: [
          {
            _key: '0b9d3d0e062e0',
            _type: 'span',
            marks: ['strong', 'em'],
            text: 'Innocent',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '13c0d80244b6',
        _type: 'block',
        children: [
          {
            _key: 'ae7f3a99ea44',
            _type: 'span',
            marks: [],
            text: 'Was wishing on stars the whole night. There’s video proof.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '5e690c602ff3',
        _type: 'block',
        children: [
          {
            _key: '6fbad9db1b5f',
            _type: 'span',
            marks: [],
            text: 'Found the victim, ',
          },
          {
            _key: '82df2a1d4b14',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: '163c37579a2b',
            _type: 'span',
            marks: [],
            text: ', at ',
          },
          {
            _key: 'fd2aae49deaf',
            _type: 'span',
            marks: ['em'],
            text: '2AM',
          },
          {
            _key: 'cf6140389df0',
            _type: 'span',
            marks: [],
            text: ' ',
          },
          {
            _key: '92c2471c2fa2',
            _type: 'span',
            marks: ['em'],
            text: 'ish',
          },
          {
            _key: 'fbdf519db10d',
            _type: 'span',
            marks: [],
            text: ', and took care of him with the help of ',
          },
          {
            _key: '525bbb371472',
            _type: 'span',
            marks: ['em'],
            text: 'Sterling ',
          },
          {
            _key: '0c0be28eca10',
            _type: 'span',
            marks: [],
            text: '&',
          },
          {
            _key: 'fcefa731408a',
            _type: 'span',
            marks: ['em'],
            text: ' Stitches',
          },
          {
            _key: '01877fc5393c',
            _type: 'span',
            marks: [],
            text: '.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionD: [
      {
        _key: '9c4ff1f07936',
        _type: 'block',
        children: [
          {
            _key: '82ae68983538',
            _type: 'span',
            marks: ['em'],
            text: 'My client who logged off.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '224c38292bac',
        _type: 'block',
        children: [
          {
            _key: '484edd16b038',
            _type: 'span',
            marks: ['strong', 'em'],
            text: 'Innocent',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
      },
      {
        _key: '6ceb36a5cdce',
        _type: 'block',
        children: [
          {
            _key: '9fe5736aa834',
            _type: 'span',
            marks: [],
            text: 'Was wishing on stars the whole night. There’s video proof.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'e970123dc4e0',
        _type: 'block',
        children: [
          {
            _key: '25cef610a1e1',
            _type: 'span',
            marks: [],
            text: 'Found the victim, ',
          },
          {
            _key: '92e70d64a27a',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: '2123a59d56f1',
            _type: 'span',
            marks: [],
            text: ', at ',
          },
          {
            _key: 'f90593b8d16d',
            _type: 'span',
            marks: ['em'],
            text: '2AM',
          },
          {
            _key: 'b5dbbf34da78',
            _type: 'span',
            marks: [],
            text: ' ',
          },
          {
            _key: 'b99d45bf085d',
            _type: 'span',
            marks: ['em'],
            text: 'ish',
          },
          {
            _key: '58c040e8b9e1',
            _type: 'span',
            marks: [],
            text: ', and took care of him with the help of ',
          },
          {
            _key: '941c64464036',
            _type: 'span',
            marks: ['em'],
            text: 'Sterling ',
          },
          {
            _key: '2455fcb74ea2',
            _type: 'span',
            marks: [],
            text: '&',
          },
          {
            _key: 'cca66a409684',
            _type: 'span',
            marks: ['em'],
            text: ' Stitches',
          },
          {
            _key: 'e70857ec46d5',
            _type: 'span',
            marks: [],
            text: '.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    imageUrl:
      'https://cdn.sanity.io/images/qvonp967/production/a584821da98bc184118e58225c025ddb8d3fbcc9-216x216.png',
    name: 'Ñenn',
    nickname: ['representative', 'Representative'],
  },
  {
    descriptionA: [
      {
        _key: 'cdf571225ca2',
        _type: 'block',
        children: [
          {
            _key: '4e2c43597af4',
            _type: 'span',
            marks: ['em'],
            text: 'The squirrel tha',
          },
          {
            _key: 'd67dfbf45ac8',
            _type: 'span',
            marks: ['em'],
            text: 't is primarily accusing my client.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionB: [
      {
        _key: 'ad82c94d7275',
        _type: 'block',
        children: [
          {
            _key: '4367e6d0a28c',
            _type: 'span',
            marks: ['em'],
            text: 'My partner(?) who is trying to imprison my client.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '459fc57fcfd2',
        _type: 'block',
        children: [
          {
            _key: '77513026eaa4',
            _type: 'span',
            marks: [],
            text: 'Spent the night with ',
          },
          {
            _key: '3601fcc04e1f',
            _type: 'span',
            marks: ['em'],
            text: 'Chadder ',
          },
          {
            _key: '126e18649aab',
            _type: 'span',
            marks: [],
            text: '& ',
          },
          {
            _key: '7334559f8315',
            _type: 'span',
            marks: ['em'],
            text: 'Merengue',
          },
          {
            _key: '07a7de347d16',
            _type: 'span',
            marks: [],
            text: ' at their place.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'fdaacc7af259',
        _type: 'block',
        children: [
          {
            _key: '8a648a1a2016',
            _type: 'span',
            marks: [],
            text: 'Was “totes conscious the whole time.”',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'cfe6081f93a3',
        _type: 'block',
        children: [
          {
            _key: '7d46be46dd81',
            _type: 'span',
            marks: [],
            text: 'Convinced ',
          },
          {
            _key: '37c62b494e37',
            _type: 'span',
            marks: ['em'],
            text: 'Ñenn',
          },
          {
            _key: 'a8cea089135e',
            _type: 'span',
            marks: [],
            text: ' did it.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionC: [
      {
        _key: 'f0e75a6af5c0',
        _type: 'block',
        children: [
          {
            _key: 'ec453cf083510',
            _type: 'span',
            marks: ['em'],
            text: 'My partner who is now on the same page as me.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'ed146dfab70b',
        _type: 'block',
        children: [
          {
            _key: '4e59d65aebdf0',
            _type: 'span',
            marks: ['strong', 'em'],
            text: 'Innocent',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'e5abca5152cc',
        _type: 'block',
        children: [
          {
            _key: '801ea64bb83c',
            _type: 'span',
            marks: [],
            text: 'Spent last night unconscious at ',
          },
          {
            _key: '6159ef953648',
            _type: 'span',
            marks: ['em'],
            text: 'Chadder’s',
          },
          {
            _key: '83b3216dd9b2',
            _type: 'span',
            marks: [],
            text: ' & ',
          },
          {
            _key: 'f03304be1439',
            _type: 'span',
            marks: ['em'],
            text: 'Merengue’s',
          },
          {
            _key: '34023eefe824',
            _type: 'span',
            marks: [],
            text: ' place.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionD: [
      {
        _key: '47eb0b3f8aa2',
        _type: 'block',
        children: [
          {
            _key: '544698ef4eb6',
            _type: 'span',
            marks: ['em'],
            text: 'My partner who I relatively trust now.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '20debd53bbc3',
        _type: 'block',
        children: [
          {
            _key: '0e6ce8940c7e',
            _type: 'span',
            marks: ['strong', 'em'],
            text: 'Innocent',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
      },
      {
        _key: 'b3ec257407a4',
        _type: 'block',
        children: [
          {
            _key: 'fb82c3a731e8',
            _type: 'span',
            marks: [],
            text: 'Spent last night unconscious at ',
          },
          {
            _key: 'de94b60c38bf',
            _type: 'span',
            marks: ['em'],
            text: 'Chadder’s',
          },
          {
            _key: 'b5deef535506',
            _type: 'span',
            marks: [],
            text: ' & ',
          },
          {
            _key: '6f858e27cd1f',
            _type: 'span',
            marks: ['em'],
            text: 'Merengue’s',
          },
          {
            _key: '7121022aa6a7',
            _type: 'span',
            marks: [],
            text: ' place.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    imageUrl:
      'https://cdn.sanity.io/images/qvonp967/production/c803c1251600e1762a9a7c129a6834a3f86adf73-128x128.png',
    name: 'Agent S',
  },
  {
    descriptionA: [
      {
        _key: '547787741587',
        _type: 'block',
        children: [
          {
            _key: '46b69b2345160',
            _type: 'span',
            marks: ['em'],
            text: 'A cat that has a lot of stuff.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionB: [
      {
        _key: '4bb7763c8dc8',
        _type: 'block',
        children: [
          {
            _key: 'c05d3337195e',
            _type: 'span',
            marks: ['em'],
            text: 'The cat that went on about how much they hate the victim.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '3c93a12f4cc4',
        _type: 'block',
        children: [
          {
            _key: '3c1dba6e7e45',
            _type: 'span',
            marks: [],
            text: 'Was on a stroll last night, back home by 11PM. Hadn’t left since.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '261ba2a7a7de',
        _type: 'block',
        children: [
          {
            _key: 'c874fd7c70e8',
            _type: 'span',
            marks: [],
            text: 'Mad at ',
          },
          {
            _key: '1fe6ef1df3a5',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: '20046a622aec',
            _type: 'span',
            marks: [],
            text: ' for breaking her bunny-day wardrobe',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '78d7912ad4c2',
        _type: 'block',
        children: [
          {
            _key: '00fa9febe348',
            _type: 'span',
            marks: [],
            text: 'Thinks ',
          },
          {
            _key: 'ac32df1b9f4e',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: 'aae2a532ef41',
            _type: 'span',
            marks: [],
            text: ' accidentally hit his head on a money rock.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionC: [
      {
        _key: '8aa6de9dd804',
        _type: 'block',
        children: [
          {
            _key: '9080560617f5',
            _type: 'span',
            marks: ['em'],
            text: 'The cat that went on about how much they hate the victim.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'a36af7e010a6',
        _type: 'block',
        children: [
          {
            _key: '745385e56157',
            _type: 'span',
            marks: ['strong', 'em'],
            text: 'Suspect',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '61ae81fd6130',
        _type: 'block',
        children: [
          {
            _key: 'f757fe9b5665',
            _type: 'span',
            marks: [],
            text: 'Claimed to be on a stroll last night, back home by ',
          },
          {
            _key: '77c9eb70a71e',
            _type: 'span',
            marks: ['em'],
            text: '11PM',
          },
          {
            _key: '5f5502a52fdc',
            _type: 'span',
            marks: [],
            text: '. Hadn’t left since.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '6d1481aed3f1',
        _type: 'block',
        children: [
          {
            _key: 'f5e347aefcbb',
            _type: 'span',
            marks: [],
            text: 'Mad at',
          },
          {
            _key: 'd800886edbe6',
            _type: 'span',
            marks: [],
            text: ' ',
          },
          {
            _key: 'cbc234e358c3',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: '27d314dbb0fc',
            _type: 'span',
            marks: [],
            text: ' fo',
          },
          {
            _key: '1e2cf25f0f49',
            _type: 'span',
            marks: [],
            text: 'r breaking her bunny-day wardrobe',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '2cda8824b4d7',
        _type: 'block',
        children: [
          {
            _key: 'b9483cf52103',
            _type: 'span',
            marks: [],
            text: 'Had the money rock theory.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionD: [
      {
        _key: 'fafb78bb3f57',
        _type: 'block',
        children: [
          {
            _key: '05e280b9011e',
            _type: 'span',
            marks: ['em'],
            text: 'The cat that went on about how much they hate the victim.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'ce1659cc5d2e',
        _type: 'block',
        children: [
          {
            _key: '611cec07517c',
            _type: 'span',
            marks: ['strong', 'em'],
            text: 'Suspect',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'ae8c7ff5b69c',
        _type: 'block',
        children: [
          {
            _key: 'c5059f4937610',
            _type: 'span',
            marks: [],
            text: 'Was out stealing last night.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'a13a14f75c2c',
        _type: 'block',
        children: [
          {
            _key: '50b2db504f36',
            _type: 'span',
            marks: [],
            text: 'Stole from ',
          },
          {
            _key: '487c22cab962',
            _type: 'span',
            marks: ['em'],
            text: 'Merengue',
          },
          {
            _key: '06776c2e9740',
            _type: 'span',
            marks: [],
            text: ', ',
          },
          {
            _key: '740ab76c3401',
            _type: 'span',
            marks: ['em'],
            text: 'Sterling',
          },
          {
            _key: '9cea9bf5b99e',
            _type: 'span',
            marks: [],
            text: ', and ',
          },
          {
            _key: '0048a2f8a625',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: 'beb9319d37a4',
            _type: 'span',
            marks: [],
            text: '.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '32d79216a62c',
        _type: 'block',
        children: [
          {
            _key: '25708895ab70',
            _type: 'span',
            marks: [],
            text: 'Has a history of being cruel to the victim.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '36dc07f69683',
        _type: 'block',
        children: [
          {
            _key: 'a7699ae0fd55',
            _type: 'span',
            marks: [],
            text: 'Saw ',
          },
          {
            _key: 'e7d1365098f7',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: '6a3014706201',
            _type: 'span',
            marks: [],
            text: ' unc',
          },
          {
            _key: 'b5a08c3fd9a6',
            _type: 'span',
            marks: [],
            text: "onscious and didn't help. Instead, she used it as an opportunity to steal from the victim.",
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    imageUrl:
      'https://cdn.sanity.io/images/qvonp967/production/ca7d7d2dc62f5913889d8a1724074c1cb174fa2f-128x128.png',
    name: 'Ankha',
  },
  {
    descriptionA: [
      {
        _key: 'e4beadc8178f',
        _type: 'block',
        children: [
          {
            _key: '8cb5243d908c',
            _type: 'span',
            marks: ['em'],
            text: 'A cat that lives at the dump.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionB: [
      {
        _key: '247cd44daafb',
        _type: 'block',
        children: [
          {
            _key: 'aa6558f2a3a1',
            _type: 'span',
            marks: ['em'],
            text: 'A friendly feral cat that lives at the dump.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'dae8fbedb577',
        _type: 'block',
        children: [
          {
            _key: 'f213da7bddaa',
            _type: 'span',
            marks: [],
            text: 'Was “wandering around” last night.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'c4080613d330',
        _type: 'block',
        children: [
          {
            _key: '2e8405c3ef58',
            _type: 'span',
            marks: [],
            text: 'Talked to ',
          },
          {
            _key: 'd1b2e9e54651',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: '7065d0f36b95',
            _type: 'span',
            marks: [],
            text: ' before the incident.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '15e8225dc81b',
        _type: 'block',
        children: [
          {
            _key: '767fbb84af6b',
            _type: 'span',
            marks: [],
            text: 'Claims he went to check his mail.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'c0ceb729f722',
        _type: 'block',
        children: [
          {
            _key: '646bf748d22d',
            _type: 'span',
            marks: [],
            text: 'Steered him away from a “butt load” o',
          },
          {
            _key: '8c5574ff5910',
            _type: 'span',
            marks: [],
            text: 'f ',
          },
          {
            _key: 'ea10dab3f490',
            _type: 'span',
            marks: [],
            text: 'pitfalls',
          },
          {
            _key: 'eb86c5a78b3e',
            _type: 'span',
            marks: [],
            text: '.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'fd3e2d0c9b6e',
        _type: 'block',
        children: [
          {
            _key: '92bddfb88b6b',
            _type: 'span',
            marks: [],
            text: 'Blames ',
          },
          {
            _key: 'e93167139c4d',
            _type: 'span',
            marks: ['em'],
            text: 'Elvis',
          },
          {
            _key: 'c18d45eb15c3',
            _type: 'span',
            marks: [],
            text: '.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionC: [
      {
        _key: '83fa4d7fd228',
        _type: 'block',
        children: [
          {
            _key: '5a5ca363b2f0',
            _type: 'span',
            marks: ['em'],
            text: 'A friendly feral cat that lives at the dump.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '2b7434d14159',
        _type: 'block',
        children: [
          {
            _key: 'f2a476eb9648',
            _type: 'span',
            marks: ['strong', 'em'],
            text: 'Suspect',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '1dfccc902a8e',
        _type: 'block',
        children: [
          {
            _key: 'c143adcd1d8b',
            _type: 'span',
            marks: [],
            text: 'Claims she was “wandering around” last night.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'c372600eaac0',
        _type: 'block',
        children: [
          {
            _key: '2c03cf2b7ef8',
            _type: 'span',
            marks: [],
            text: 'Talked to ',
          },
          {
            _key: '71cc283884ed',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: '785399e47cb3',
            _type: 'span',
            marks: [],
            text: ' before the incident.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '3a3bd62fac19',
        _type: 'block',
        children: [
          {
            _key: '26e7241cfaa6',
            _type: 'span',
            marks: [],
            text: 'Claims he went to check his mail.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'd7d5b272923a',
        _type: 'block',
        children: [
          {
            _key: '225b22d2c39a',
            _type: 'span',
            marks: [],
            text: 'Steered him away from a “b',
          },
          {
            _key: '26575c1f87a6',
            _type: 'span',
            marks: [],
            text: 'utt load” of ',
          },
          {
            _key: '3899f94d1e97',
            _type: 'span',
            marks: [],
            text: 'pitfalls',
          },
          {
            _key: 'c13fc4b49aef',
            _type: 'span',
            marks: [],
            text: '.',
          },
          {
            _key: '3e09cab6e0c3',
            _type: 'span',
            marks: [],
            text: '',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionD: [
      {
        _key: 'fab451e5eb87',
        _type: 'block',
        children: [
          {
            _key: '41ae42299476',
            _type: 'span',
            marks: ['em'],
            text: 'A friendly feral cat that lives at the dump.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'ef1b65823c6a',
        _type: 'block',
        children: [
          {
            _key: 'b3a0e529830b',
            _type: 'span',
            marks: ['strong', 'em'],
            text: 'Suspect',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'f7290c96e6fe',
        _type: 'block',
        children: [
          {
            _key: '383638457775',
            _type: 'span',
            marks: [],
            text: 'Burie',
          },
          {
            _key: '47a297bc8d0e',
            _type: 'span',
            marks: [],
            text: 'd ',
          },
          {
            _key: 'c59e5734f6ea',
            _type: 'span',
            marks: [],
            text: 'pitfalls',
          },
          {
            _key: 'f473cab8ae84',
            _type: 'span',
            marks: [],
            text: ' in the castle courtyard la',
          },
          {
            _key: '75ce9fa43bf6',
            _type: 'span',
            marks: [],
            text: 'st night.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'db832b6b87b5',
        _type: 'block',
        children: [
          {
            _key: '32c2e738407c',
            _type: 'span',
            marks: [],
            text: 'Is the reason ',
          },
          {
            _key: '0a75080fbf72',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: '3c48379475da',
            _type: 'span',
            marks: [],
            text: ' checked his mail.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '9a2e3f4a9682',
        _type: 'block',
        children: [
          {
            _key: 'fafa6aee0b1d',
            _type: 'span',
            marks: [],
            text: 'Wants to keep the murder weapon as decorative furniture.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    imageUrl:
      'https://cdn.sanity.io/images/qvonp967/production/c756079606d8b0131453968eb6a1535cbe7a4f81-128x128.png',
    name: 'Katt',
    nickname: ['Big Bad Badingdong'],
  },
  {
    descriptionA: [
      {
        _key: '7fa71d8e836b',
        _type: 'block',
        children: [
          {
            _key: '3f932af4b20e0',
            _type: 'span',
            marks: ['em'],
            text: 'Restaurant owner that looks like cheese. ',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionB: [
      {
        _key: '3394da035aa3',
        _type: 'block',
        children: [
          {
            _key: 'e0d88ad8377b',
            _type: 'span',
            marks: ['em'],
            text: 'Restaurant owner that is(?) made of cheese(?)?',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'ca60135909cb',
        _type: 'block',
        children: [
          {
            _key: 'b0147b8cf74d',
            _type: 'span',
            marks: [],
            text: 'Was with an unconscious ',
          },
          {
            _key: '85ada1e79e9c',
            _type: 'span',
            marks: ['em'],
            text: 'Agent S 9:45',
          },
          {
            _key: 'b7ed9c133220',
            _type: 'span',
            marks: [],
            text: ' to ',
          },
          {
            _key: '97bef77f267c',
            _type: 'span',
            marks: ['em'],
            text: '10:30ish',
          },
          {
            _key: 'd06960986015',
            _type: 'span',
            marks: [],
            text: ' ',
          },
          {
            _key: '194e5646dc97',
            _type: 'span',
            marks: ['em'],
            text: 'PM at Chadder’s',
          },
          {
            _key: 'af020a97c930',
            _type: 'span',
            marks: [],
            text: ' & ',
          },
          {
            _key: '0ccaf5b7e9cd',
            _type: 'span',
            marks: ['em'],
            text: 'Merengue’s',
          },
          {
            _key: '3a4e9d6a10e0',
            _type: 'span',
            marks: [],
            text: ' place.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '9ac48aa4979e',
        _type: 'block',
        children: [
          {
            _key: '1863e83bc9cb',
            _type: 'span',
            marks: [],
            text: 'States ',
          },
          {
            _key: 'e103925e3bb2',
            _type: 'span',
            marks: ['em'],
            text: 'Merengue',
          },
          {
            _key: '9b8451fdc388',
            _type: 'span',
            marks: [],
            text: ' and him are alibis.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionC: [
      {
        _key: 'ca8a89108f25',
        _type: 'block',
        children: [
          {
            _key: 'cefec7c6f11c',
            _type: 'span',
            marks: ['em'],
            text: 'Restaurant owner that is(?) made of cheese(?)?',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '021d68564c5e',
        _type: 'block',
        children: [
          {
            _key: '533d6575afba',
            _type: 'span',
            marks: ['strong', 'em'],
            text: 'Innocent',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'e56307531406',
        _type: 'block',
        children: [
          {
            _key: '189b64f6c2b3',
            _type: 'span',
            marks: [],
            text: 'Was with an unconscious ',
          },
          {
            _key: '7c3021ae54b3',
            _type: 'span',
            marks: ['em'],
            text: 'Agent S 9:45 to 10:30ish PM',
          },
          {
            _key: '8d4766c0722f',
            _type: 'span',
            marks: [],
            text: ' at ',
          },
          {
            _key: '2de98891521d',
            _type: 'span',
            marks: ['em'],
            text: 'Chadder’s',
          },
          {
            _key: 'ea5c2bbc6366',
            _type: 'span',
            marks: [],
            text: ' & ',
          },
          {
            _key: '9399d6aa0d97',
            _type: 'span',
            marks: ['em'],
            text: 'Merengue’s',
          },
          {
            _key: 'ca66ebc24e0f',
            _type: 'span',
            marks: [],
            text: ' place.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '99a1db5ad02a',
        _type: 'block',
        children: [
          {
            _key: '723bfeec4240',
            _type: 'span',
            marks: [],
            text: 'Lied for ',
          },
          {
            _key: '1fe5b8a6c51f',
            _type: 'span',
            marks: ['em'],
            text: 'Merengue',
          },
          {
            _key: 'fdd6d5cd008e',
            _type: 'span',
            marks: ['em'],
            text: '.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '9cacdbb212e6',
        _type: 'block',
        children: [
          {
            _key: '9b7bea1c58c6',
            _type: 'span',
            marks: [],
            text: 'Alibis with ',
          },
          {
            _key: 'b65ea59a3525',
            _type: 'span',
            marks: ['em'],
            text: 'Agent S',
          },
          {
            _key: '06ac045121ab',
            _type: 'span',
            marks: [],
            text: ', but not ',
          },
          {
            _key: '04dd9e3aaca0',
            _type: 'span',
            marks: ['em'],
            text: 'Marengue',
          },
          {
            _key: '17c9033a5a8a',
            _type: 'span',
            marks: [],
            text: '.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionD: [
      {
        _key: 'f209985745ee',
        _type: 'block',
        children: [
          {
            _key: '0512e4c31f21',
            _type: 'span',
            marks: ['em'],
            text: 'Restaurant owner that is(?) made of cheese(?)?',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'a732840dcd9a',
        _type: 'block',
        children: [
          {
            _key: '4cce0657ec69',
            _type: 'span',
            marks: ['strong', 'em'],
            text: 'Innocent',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '9f1b36709c70',
        _type: 'block',
        children: [
          {
            _key: '85013f027ebd',
            _type: 'span',
            marks: [],
            text: 'Was with an unconscious ',
          },
          {
            _key: 'a1ee05a7f265',
            _type: 'span',
            marks: ['em'],
            text: 'Agent S 9:45 to 10:30ish PM ',
          },
          {
            _key: 'd1e97f51b410',
            _type: 'span',
            marks: [],
            text: 'at ',
          },
          {
            _key: '3d484a9aae35',
            _type: 'span',
            marks: ['em'],
            text: 'Chadder’s',
          },
          {
            _key: '16a6be252fbf',
            _type: 'span',
            marks: [],
            text: ' & ',
          },
          {
            _key: '67cd4ac3f463',
            _type: 'span',
            marks: ['em'],
            text: 'Merengue’s',
          },
          {
            _key: 'c596897cd0ff',
            _type: 'span',
            marks: [],
            text: ' place.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '8c40c9a1d85a',
        _type: 'block',
        children: [
          {
            _key: '46e64b742118',
            _type: 'span',
            marks: [],
            text: 'Lied for ',
          },
          {
            _key: '2468b7c9444c',
            _type: 'span',
            marks: ['em'],
            text: 'Merengue.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'fb06d6163e8c',
        _type: 'block',
        children: [
          {
            _key: '09554cf88055',
            _type: 'span',
            marks: [],
            text: 'Alibis with ',
          },
          {
            _key: 'a4c21a52486e',
            _type: 'span',
            marks: ['em'],
            text: 'Agent S',
          },
          {
            _key: '5cc53dc486ce',
            _type: 'span',
            marks: [],
            text: ', but not ',
          },
          {
            _key: '4e61ccfb9311',
            _type: 'span',
            marks: ['em'],
            text: 'Marengue',
          },
          {
            _key: 'd72c36866fc4',
            _type: 'span',
            marks: [],
            text: '.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    imageUrl:
      'https://cdn.sanity.io/images/qvonp967/production/cf7e5f8fc2217c8e5ea4d9ca1fbb158891587d55-128x128.png',
    name: 'Chadder',
  },
  {
    descriptionA: [
      {
        _key: '881ec74ea6fb',
        _type: 'block',
        children: [
          {
            _key: '30fc0fabf1cc0',
            _type: 'span',
            marks: ['em'],
            text: 'A teddy bear?',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionB: [
      {
        _key: '2ba910fa78e5',
        _type: 'block',
        children: [
          {
            _key: '0f5cec3b045c',
            _type: 'span',
            marks: ['em'],
            text: "The victim's surgeon and best friend.",
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'e18a164050cf',
        _type: 'block',
        children: [
          {
            _key: 'e114e77c89da',
            _type: 'span',
            marks: [],
            text: '“...played with my toys, grabbed a package from res services, read a book, and fell asleep cuddling with ‘Mama bear’ and the rest of the teddy bear family.”',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '5bf7a73ac90c',
        _type: 'block',
        children: [
          {
            _key: '292a96675b48',
            _type: 'span',
            marks: [],
            text: 'The package was the SLR camera.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'd70c19bb2241',
        _type: 'block',
        children: [
          {
            _key: '424187e22401',
            _type: 'span',
            marks: [],
            text: 'Woken by ',
          },
          {
            _key: '0ea3d44314f5',
            _type: 'span',
            marks: ['em'],
            text: 'Ñenn',
          },
          {
            _key: '02f7ee75ddb3',
            _type: 'span',
            marks: [],
            text: ' to give ',
          },
          {
            _key: '6e4792d3608d',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: '141e4ca890b2',
            _type: 'span',
            marks: [],
            text: ' stitches. He used the sturdy sewing box.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '81a5c5fb615d',
        _type: 'block',
        children: [
          {
            _key: 'dd2df10642d5',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: 'c313567a2b3c',
            _type: 'span',
            marks: [],
            text: '’s best friend. Scared of losing him.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '8652397013b6',
        _type: 'block',
        children: [
          {
            _key: '862cbb4a8590',
            _type: 'span',
            marks: [],
            text: 'Thinks it was an accident, or ',
          },
          {
            _key: 'f274fcb2eac7',
            _type: 'span',
            marks: ['em'],
            text: 'Ankha',
          },
          {
            _key: '3a4dca5f884b',
            _type: 'span',
            marks: [],
            text: ' did it.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionC: [
      {
        _key: 'c9d3f23525a2',
        _type: 'block',
        children: [
          {
            _key: '6758bfbf3f73',
            _type: 'span',
            marks: ['em'],
            text: "The victim's surgeon and best friend.",
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '530440c74b03',
        _type: 'block',
        children: [
          {
            _key: '6408121cb638',
            _type: 'span',
            marks: ['strong', 'em'],
            text: 'Suspect',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'e56d9de6ed2d',
        _type: 'block',
        children: [
          {
            _key: 'ab9822a21d33',
            _type: 'span',
            marks: [],
            text: 'Got a packag',
          },
          {
            _key: '86429bf85f75',
            _type: 'span',
            marks: [],
            text: 'e yesterday, the ',
          },
          {
            _key: '18afcd94dda2',
            _type: 'span',
            marks: [],
            text: 'SLR camera',
          },
          {
            _key: '3a18ae7d1bdd',
            _type: 'span',
            marks: [],
            text: '.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'dc2401481c10',
        _type: 'block',
        children: [
          {
            _key: 'd680c8adb316',
            _type: 'span',
            marks: [],
            text: 'Gave ',
          },
          {
            _key: 'd83a3181e663',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: '79ffa160dc70',
            _type: 'span',
            marks: [],
            text: ' stitches this morning, with a ',
          },
          {
            _key: 'd9c19c953179',
            _type: 'span',
            marks: [],
            text: 'sturdy sewing box',
          },
          {
            _key: '8e20db8936f7',
            _type: 'span',
            marks: [],
            text: '.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'b0225279a8a7',
        _type: 'block',
        children: [
          {
            _key: 'f726aa179fcb',
            _type: 'span',
            marks: [],
            text: 'Owner of the almost-murder weapon, the ',
          },
          {
            _key: '0fd93c0d7bb3',
            _type: 'span',
            marks: [],
            text: 'sewing machine',
          },
          {
            _key: '979487aee451',
            _type: 'span',
            marks: [],
            text: '.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionD: [
      {
        _key: '3fb4f7932b92',
        _type: 'block',
        children: [
          {
            _key: 'd480401a89a2',
            _type: 'span',
            marks: ['em'],
            text: "The victim's surgeon and best friend.",
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '25e394ca2f19',
        _type: 'block',
        children: [
          {
            _key: '4a650401b2b1',
            _type: 'span',
            marks: ['strong', 'em'],
            text: 'Suspect',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
      },
      {
        _key: '7d561650eb75',
        _type: 'block',
        children: [
          {
            _key: 'fd8b7efabfd4',
            _type: 'span',
            marks: [],
            text: 'Used the package he got yesterday, the SLR camera, to take pictures around the island last night.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '051c17e59af8',
        _type: 'block',
        children: [
          {
            _key: '47ac5e99dc7c',
            _type: 'span',
            marks: [],
            text: 'Gave ',
          },
          {
            _key: '9a58f9f4cf90',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: 'c082c09e5e1d',
            _type: 'span',
            marks: [],
            text: ' stitches this morning, with a sturdy sewing box.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '0767564684c8',
        _type: 'block',
        children: [
          {
            _key: '0e04c34a4c0e',
            _type: 'span',
            marks: [],
            text: 'Owner of the almost-murder weapon, the sewing machine.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '9750291b8728',
        _type: 'block',
        children: [
          {
            _key: 'b9d3b271eb8a',
            _type: 'span',
            marks: [],
            text: 'Saw Lucky unconscious and supposedly did nothing.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    imageUrl:
      'https://cdn.sanity.io/images/qvonp967/production/44ac2f0c2b1d00f25e24e0a3d932c1853cb70b19-128x128.png',
    name: 'Stitches',
  },
  {
    descriptionA: [
      {
        _key: '379e1939501a',
        _type: 'block',
        children: [
          {
            _key: 'f33b176fa2a60',
            _type: 'span',
            marks: ['em'],
            text: 'A fancy unicorn.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionB: [
      {
        _key: '5fa0aed74747',
        _type: 'block',
        children: [
          {
            _key: 'de085c13b214',
            _type: 'span',
            marks: ['em'],
            text: 'A melodramatic unicorn.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'f18b1bc6cc8d',
        _type: 'block',
        children: [
          {
            _key: '9e2aade1a05a',
            _type: 'span',
            marks: [],
            text: 'Insists ',
          },
          {
            _key: '01561a4eb377',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: 'd487e889cae8',
            _type: 'span',
            marks: [],
            text: ' is dead.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '544f679db84d',
        _type: 'block',
        children: [
          {
            _key: '6b42caddf993',
            _type: 'span',
            marks: [],
            text: 'Was writing poetry in the rain last night.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'eabef0e7f7d0',
        _type: 'block',
        children: [
          {
            _key: 'b5ac6132e8fe',
            _type: 'span',
            marks: [],
            text: 'Claims to be ',
          },
          {
            _key: '1ac260b04e94',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky’s',
          },
          {
            _key: 'afd12bdc0c1b',
            _type: 'span',
            marks: [],
            text: ' ex.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'd747665b0bc4',
        _type: 'block',
        children: [
          {
            _key: '8b3eb7db983e',
            _type: 'span',
            marks: [],
            text: 'Claims ',
          },
          {
            _key: '4e65abbfeea0',
            _type: 'span',
            marks: ['em'],
            text: 'Ñenn',
          },
          {
            _key: 'eb7de784fd39',
            _type: 'span',
            marks: [],
            text: ' did the crime out of jealousy.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionC: [
      {
        _key: 'e9642d48608b',
        _type: 'block',
        children: [
          {
            _key: 'beedb3d2d3e6',
            _type: 'span',
            marks: ['em'],
            text: 'A melodramatic unicorn.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'd71432903b22',
        _type: 'block',
        children: [
          {
            _key: 'd385d30db39f0',
            _type: 'span',
            marks: ['strong', 'em'],
            text: 'Innocent',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'f8eee2893d67',
        _type: 'block',
        children: [
          {
            _key: '4f42ad82a135',
            _type: 'span',
            marks: [],
            text: 'Not the almost-murderer and probably not ',
          },
          {
            _key: '90e2eb7d9438',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: 'b2d82870723e',
            _type: 'span',
            marks: [],
            text: '’s ex.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'c88839e75b88',
        _type: 'block',
        children: [
          {
            _key: '07ea4042958d',
            _type: 'span',
            marks: [],
            text: 'Apparently has been acting this whole time',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '77dd5a208ef0',
        _type: 'block',
        children: [
          {
            _key: '2e0d7cee96f5',
            _type: 'span',
            marks: [],
            text: 'Has no useful information.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionD: [
      {
        _key: '193470d9d8d3',
        _type: 'block',
        children: [
          {
            _key: '6b7978e417b0',
            _type: 'span',
            marks: ['em'],
            text: 'A melodramatic unicorn.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'b9268e3d74b5',
        _type: 'block',
        children: [
          {
            _key: 'b4929e162f6f',
            _type: 'span',
            marks: ['strong', 'em'],
            text: 'Innocent',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
      },
      {
        _key: 'c058e6ae80a4',
        _type: 'block',
        children: [
          {
            _key: '479c7719029e',
            _type: 'span',
            marks: [],
            text: 'Not the almost-murderer and probably not ',
          },
          {
            _key: 'd9bdc08a884b',
            _type: 'span',
            marks: ['em'],
            text: 'Lucky',
          },
          {
            _key: '6ed0566930aa',
            _type: 'span',
            marks: [],
            text: '’s ex.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'bc1292d9ed14',
        _type: 'block',
        children: [
          {
            _key: '368916a30f1c',
            _type: 'span',
            marks: [],
            text: 'Apparently has been acting this whole time',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '81270686579a',
        _type: 'block',
        children: [
          {
            _key: '17ce0c7299be',
            _type: 'span',
            marks: [],
            text: 'Has no useful information.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    imageUrl:
      'https://cdn.sanity.io/images/qvonp967/production/44bd167b4692f6c6b017353a2855b79a57069f35-128x128.png',
    name: 'Julian',
  },
  {
    descriptionA: [
      {
        _key: '09d77f44b8ee',
        _type: 'block',
        children: [
          {
            _key: '61ee8b7319d4',
            _type: 'span',
            marks: ['em'],
            text: 'The not-so-lucky victim.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionB: [
      {
        _key: 'd1710c666783',
        _type: 'block',
        children: [
          {
            _key: '2d61c5e33c3e',
            _type: 'span',
            marks: ['em'],
            text: 'The not-so-lucky victim.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '79f4d6171352',
        _type: 'block',
        children: [
          {
            _key: '3d52d8199d97',
            _type: 'span',
            marks: [],
            text: 'Went out for unknown reasons.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '811b8f3f9d1d',
        _type: 'block',
        children: [
          {
            _key: '4f65045e658c',
            _type: 'span',
            marks: [],
            text: 'When knocked out, heard a bug say it’s sorry and someone said his name while knocking on his head.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '79d0c1987476',
        _type: 'block',
        children: [
          {
            _key: '56881739760d',
            _type: 'span',
            marks: [],
            text: 'Katt said they talked last night.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'a86b2539b307',
        _type: 'block',
        children: [
          {
            _key: 'e63323d7a686',
            _type: 'span',
            marks: [],
            text: 'Thinks it was an accident.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionC: [
      {
        _key: '94b715cb3d19',
        _type: 'block',
        children: [
          {
            _key: '181a61693ccd',
            _type: 'span',
            marks: ['em'],
            text: 'The not-so-lucky victim.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '9322e914e395',
        _type: 'block',
        children: [
          {
            _key: '2628d369ba3b',
            _type: 'span',
            marks: ['strong', 'em'],
            text: 'Innocent',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'e1a55aeb3b4c',
        _type: 'block',
        children: [
          {
            _key: '372702587583',
            _type: 'span',
            marks: [],
            text: 'Went out to check his mail and was attacked on the way back.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'b3fccd4f3096',
        _type: 'block',
        children: [
          {
            _key: '4a0b2bec4a70',
            _type: 'span',
            marks: [],
            text: 'Has poor memory.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '334f1345ea2e',
        _type: 'block',
        children: [
          {
            _key: 'f3e52812a408',
            _type: 'span',
            marks: [],
            text: 'When knocked out heard a bug say it’s sorry and someone said his name while knocking on his head.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionD: [
      {
        _key: '279354606fb7',
        _type: 'block',
        children: [
          {
            _key: '4a722388b6f2',
            _type: 'span',
            marks: ['em'],
            text: 'The not-so-lucky victim.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '158b813f4e26',
        _type: 'block',
        children: [
          {
            _key: 'e64b59e2ce0b',
            _type: 'span',
            marks: ['strong', 'em'],
            text: 'Innocent',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'cc4f7b63959d',
        _type: 'block',
        children: [
          {
            _key: '6245bd450c1b',
            _type: 'span',
            marks: [],
            text: 'Went out to check his mail and was attacked on the way back.',
          },
          {
            _key: 'b92998abbac1',
            _type: 'span',
            marks: [],
            text: '',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '8aed7f2ce9b2',
        _type: 'block',
        children: [
          {
            _key: '3dfab7fa385d0',
            _type: 'span',
            marks: [],
            text: 'Was talking to a bu',
          },
          {
            _key: '2ae7d02c05c4',
            _type: 'span',
            marks: [],
            text: 'g, ',
          },
          {
            _key: '9f75b280b180',
            _type: 'span',
            marks: ['em'],
            text: 'Thomas',
          },
          {
            _key: '1051b28b7c19',
            _type: 'span',
            marks: [],
            text: ', w',
          },
          {
            _key: 'f960df4916e1',
            _type: 'span',
            marks: [],
            text: 'hen attacked.',
          },
          {
            _key: '15269e90f72a',
            _type: 'span',
            marks: [],
            text: '',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '1a46596cc901',
        _type: 'block',
        children: [
          {
            _key: '931dd79b470f',
            _type: 'span',
            marks: [],
            text: 'Believes no one wants to hurt him.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'bd91b10fa053',
        _type: 'block',
        children: [
          {
            _key: '0d9e42c92fd7',
            _type: 'span',
            marks: [],
            text: 'Does not want us to continue with the trial.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    imageUrl:
      'https://cdn.sanity.io/images/qvonp967/production/2b649fd78abe3c246bc89451bbba99d65c2d17d1-128x128.png',
    name: 'Lucky',
    nickname: ['Lucks', 'Luckyyy'],
  },
  {
    descriptionA: [
      {
        _key: '04a60f287aaf',
        _type: 'block',
        children: [
          {
            _key: 'ea8f46e3e83a0',
            _type: 'span',
            marks: ['em'],
            text: 'A bakery owner that looks like a cake.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionB: [
      {
        _key: '0657226974b0',
        _type: 'block',
        children: [
          {
            _key: '5922638835d3',
            _type: 'span',
            marks: ['em'],
            text: 'The sweet bakery owner who isn’t a cake(?).',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '4dd1a811f077',
        _type: 'block',
        children: [
          {
            _key: '79ba199dc351',
            _type: 'span',
            marks: [],
            text: 'Was at her & ',
          },
          {
            _key: '0b8a8c40cd79',
            _type: 'span',
            marks: ['em'],
            text: 'Chadder’s',
          },
          {
            _key: 'f5bc2390d371',
            _type: 'span',
            marks: [],
            text: ' place when ',
          },
          {
            _key: 'e019a5718197',
            _type: 'span',
            marks: ['em'],
            text: 'Agent S',
          },
          {
            _key: '1dad609d9661',
            _type: 'span',
            marks: [],
            text: ' fell unconscious.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '22d394d8547c',
        _type: 'block',
        children: [
          {
            _key: '8f5b04f4cb1f',
            _type: 'span',
            marks: [],
            text: 'Went to the “toilet” before ',
          },
          {
            _key: '738acbe8f0be',
            _type: 'span',
            marks: ['em'],
            text: 'Agent S',
          },
          {
            _key: '05e6983c1ac8',
            _type: 'span',
            marks: [],
            text: ' woke up.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '7bab0eaf05e0',
        _type: 'block',
        children: [
          {
            _key: '4d214cfb76d7',
            _type: 'span',
            marks: [],
            text: 'Blames ',
          },
          {
            _key: '7070ad702fe1',
            _type: 'span',
            marks: ['em'],
            text: 'Ñenn',
          },
          {
            _key: 'a700508e6688',
            _type: 'span',
            marks: [],
            text: '.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '6b5e0b38a1fa',
        _type: 'block',
        children: [
          {
            _key: '6a9f4d4c79de',
            _type: 'span',
            marks: [],
            text: 'Asked if I could look for her missing kitchen appliances.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionC: [
      {
        _key: 'a2b5007c6e7d',
        _type: 'block',
        children: [
          {
            _key: '61d12285b900',
            _type: 'span',
            marks: ['em'],
            text: 'The sweet(?) bakery owner who isn’t a cake(?).',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '2d8aa6311c69',
        _type: 'block',
        children: [
          {
            _key: 'fe0eac0356d8',
            _type: 'span',
            marks: ['strong', 'em'],
            text: 'Suspect',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '61a2cad82655',
        _type: 'block',
        children: [
          {
            _key: '9a3dbc24a3b8',
            _type: 'span',
            marks: [],
            text: 'Lied about not being out last night.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'a9dbd367e260',
        _type: 'block',
        children: [
          {
            _key: '8f4e5a90e9ba',
            _type: 'span',
            marks: [],
            text: 'Asked if I could look for her missing kitchen appliances.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    descriptionD: [
      {
        _key: '428a643e6ca3',
        _type: 'block',
        children: [
          {
            _key: '88fc3052f3180',
            _type: 'span',
            marks: ['em'],
            text: 'The not-as-sweet bakery owner.',
          },
        ],
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '58afc16a24d9',
        _type: 'block',
        children: [
          {
            _key: '333463e2336e0',
            _type: 'span',
            marks: ['strong', 'em'],
            text: 'Suspect',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: 'df46348ede32',
        _type: 'block',
        children: [
          {
            _key: 'a16ff131b173',
            _type: 'span',
            marks: [],
            text: 'Lied about being out last night.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '9b1441f800cb',
        _type: 'block',
        children: [
          {
            _key: 'ccf20334d9d8',
            _type: 'span',
            marks: [],
            text: 'Delivered poisoned cakes to',
          },
          {
            _key: 'e25e29179b2d',
            _type: 'span',
            marks: [],
            text: ' ',
          },
          {
            _key: 'a28c9642c826',
            _type: 'span',
            marks: ['em'],
            text: 'Agent S',
          },
          {
            _key: '67be82d11d2e',
            _type: 'span',
            marks: [],
            text: ',',
          },
          {
            _key: '0d6a7dd5cde5',
            _type: 'span',
            marks: ['em'],
            text: ' Ankha',
          },
          {
            _key: '82268c44175b',
            _type: 'span',
            marks: [],
            text: ',',
          },
          {
            _key: '335ce1cd8414',
            _type: 'span',
            marks: ['em'],
            text: ' ',
          },
          {
            _key: '0f3796e4c66b',
            _type: 'span',
            marks: ['em'],
            text: 'Stitches',
          },
          {
            _key: '7ebe5d33b1d4',
            _type: 'span',
            marks: [],
            text: ',',
          },
          {
            _key: 'c893a02176c0',
            _type: 'span',
            marks: [],
            text: ' and ',
          },
          {
            _key: 'e0800ed40ad8',
            _type: 'span',
            marks: ['em'],
            text: 'Sterling’',
          },
          {
            _key: '3cd4e7655be5',
            _type: 'span',
            marks: ['em'],
            text: 's',
          },
          {
            _key: '751a1c6a4e05',
            _type: 'span',
            marks: [],
            text: ' homes as revenge.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
      {
        _key: '71e68e8f1f56',
        _type: 'block',
        children: [
          {
            _key: 'b2f166d36957',
            _type: 'span',
            marks: [],
            text: 'We retrieved her stolen kitchen appliances.',
          },
        ],
        level: 1,
        listItem: 'bullet',
        markDefs: [],
        style: 'normal',
      },
    ],
    imageUrl:
      'https://cdn.sanity.io/images/qvonp967/production/946b6345214d5588542084a2849a74e11746a5ab-128x128.png',
    name: 'Merengue',
  },
];

export default notes;
