const carouselData = [
  {
    centered: true,
    emotion: 'Pride',
    direction: 'left',
    name: 'Agent S',
  },
  {
    centered: true,
    emotion: 'notebook',
    direction: 'left',
    name: 'Agent S',
  },
  {
    centered: true,
    emotion: 'Smirking',
    direction: 'left',
    name: 'Agent S',
  },
  {
    centered: true,
    emotion: 'Thought',
    direction: 'left',
    name: 'Agent S',
  },
  {
    centered: true,
    emotion: 'Sweat',
    direction: 'left',
    name: 'Agent S',
  },
  {
    centered: true,
    emotion: 'Surprise',
    direction: 'left',
    name: 'Agent S',
  },
  {
    centered: true,
    emotion: 'Thought',
    direction: 'left',
    name: 'Merengue',
  },
  {
    centered: true,
    emotion: 'Surprise',
    direction: 'left',
    name: 'Merengue',
  },
  // {
  //   centered: true,
  //   emotion: 'Sigh',
  //   direction: 'left',
  //   name: 'Merengue',
  // },
  {
    centered: true,
    emotion: 'Sweat',
    direction: 'left',
    name: 'Merengue',
  },
  {
    centered: true,
    emotion: 'Joy',
    direction: 'left',
    name: 'Merengue',
  },
  // {
  //   centered: true,
  //   emotion: 'Smirking',
  //   direction: 'left',
  //   name: 'Julian',
  // },
  {
    centered: true,
    emotion: 'Drama',
    direction: 'left',
    name: 'Julian',
  },
  {
    centered: true,
    emotion: 'Mischief',
    direction: 'left',
    name: 'Julian',
  },
  {
    centered: true,
    emotion: 'Worry',
    direction: 'left',
    name: 'Julian',
  },
  {
    centered: true,
    emotion: 'Pride',
    direction: 'left',
    name: 'Julian',
  },
  // {
  //   centered: true,
  //   emotion: 'Luaghter',
  //   direction: 'left',
  //   name: 'Julian',
  // },
  {
    centered: true,
    emotion: 'Pride',
    direction: 'left',
    name: 'Ankha',
  },
  {
    centered: true,
    emotion: 'Thought',
    direction: 'left',
    name: 'Ankha',
  },
  // {
  //   centered: true,
  //   emotion: 'Aggregation',
  //   direction: 'left',
  //   name: 'Ankha',
  // },
  {
    centered: true,
    emotion: 'Hmph',
    direction: 'left',
    name: 'Ankha',
  },
  {
    centered: true,
    emotion: 'Ugh',
    direction: 'left',
    name: 'Ankha',
  },
  {
    centered: true,
    emotion: 'Scratch Head',
    direction: 'left',
    name: 'Ankha',
  },
  {
    centered: true,
    emotion: 'Worry',
    direction: 'left',
    name: 'Ankha',
  },
  // {
  //   centered: true,
  //   emotion: 'Fear',
  //   direction: 'left',
  //   name: 'Ankha',
  // },
  {
    centered: true,
    emotion: 'Thought',
    direction: 'left',
    name: 'Sterling',
  },
  // {
  //   centered: true,
  //   emotion: 'Aggravated',
  //   direction: 'left',
  //   name: 'Sterling',
  // },
  // {
  //   centered: true,
  //   emotion: 'Tap fingers',
  //   direction: 'left',
  //   name: 'Sterling',
  // },
  {
    centered: true,
    emotion: 'Pride',
    direction: 'left',
    name: 'Sterling',
  },
  {
    centered: true,
    emotion: 'Scratch Head',
    direction: 'left',
    name: 'Sterling',
  },
  {
    centered: true,
    emotion: 'Bewilderment',
    direction: 'left',
    name: 'Sterling',
  },
  {
    centered: true,
    emotion: 'Resignation',
    direction: 'left',
    name: 'Katt',
  },
  // {
  //   centered: true,
  //   emotion: 'Sheepness',
  //   direction: 'left',
  //   name: 'Katt',
  // },
  // {
  //   centered: true,
  //   emotion: 'Smirk',
  //   direction: 'left',
  //   name: 'Katt',
  // },
  {
    centered: true,
    emotion: 'Laughter',
    direction: 'left',
    name: 'Katt',
  },
  // {
  //   centered: true,
  //   emotion: 'Tap fingers',
  //   direction: 'left',
  //   name: 'Katt',
  // },
  {
    centered: true,
    emotion: 'Aggravation',
    direction: 'left',
    name: 'Katt',
  },
  {
    centered: true,
    emotion: 'Amazed',
    direction: 'left',
    name: 'Katt',
  },
  {
    centered: true,
    emotion: 'Intense',
    direction: 'left',
    name: 'Katt',
  },
  {
    centered: true,
    emotion: 'Thought',
    direction: 'left',
    name: 'Ñenn',
  },
  {
    centered: true,
    emotion: 'Worry',
    direction: 'left',
    name: 'Ñenn',
  },
  {
    centered: true,
    emotion: 'Amazed',
    direction: 'left',
    name: 'Ñenn',
  },
  {
    centered: true,
    emotion: 'Distressed',
    direction: 'left',
    name: 'Ñenn',
  },
  {
    centered: true,
    emotion: 'Resignation',
    direction: 'left',
    name: 'Ñenn',
  },
  // {
  //   centered: true,
  //   emotion: 'Scratch head',
  //   direction: 'left',
  //   name: 'Ñenn',
  // },
  {
    centered: true,
    emotion: 'Sorrow',
    direction: 'left',
    name: 'Ñenn',
  },
  {
    centered: true,
    emotion: 'Happiness',
    direction: 'left',
    name: 'Lucky',
  },
  {
    centered: true,
    emotion: 'Thought',
    direction: 'left',
    name: 'Lucky',
  },
  {
    centered: true,
    emotion: 'Scratch Head',
    direction: 'left',
    name: 'Lucky',
  },
  {
    centered: true,
    emotion: 'Worry',
    direction: 'left',
    name: 'Lucky',
  },
  {
    centered: true,
    emotion: 'Surprise',
    direction: 'left',
    name: 'Lucky',
  },
  // {
  //   centered: true,
  //   emotion: 'Talk',
  //   direction: 'left',
  //   name: 'Lucky',
  // },
  {
    centered: true,
    emotion: 'Pride',
    direction: 'left',
    name: 'Lucky',
  },
  {
    centered: true,
    emotion: 'Happiness',
    direction: 'left',
    name: 'Stitches',
  },
  {
    centered: true,
    emotion: 'Shyness',
    direction: 'left',
    name: 'Stitches',
  },
  {
    centered: true,
    emotion: 'Amazed',
    direction: 'left',
    name: 'Stitches',
  },
  {
    centered: true,
    emotion: 'Thought',
    direction: 'left',
    name: 'Stitches',
  },
  {
    centered: true,
    emotion: 'Pride',
    direction: 'left',
    name: 'Stitches',
  },
  {
    centered: true,
    emotion: 'Standing',
    direction: 'left',
    name: 'Stitches',
  },
  {
    centered: true,
    emotion: 'Scratch Head',
    direction: 'left',
    name: 'Elvis',
  },
  {
    centered: true,
    emotion: 'Hmph',
    direction: 'left',
    name: 'Elvis',
  },
  {
    centered: true,
    emotion: 'Happiness',
    direction: 'left',
    name: 'Elvis',
  },
  {
    centered: true,
    emotion: 'Resignation',
    direction: 'left',
    name: 'Elvis',
  },
  // {
  //   centered: true,
  //   emotion: 'Smirk',
  //   direction: 'left',
  //   name: 'Chadder',
  // },
  {
    centered: true,
    emotion: 'Resignation',
    direction: 'left',
    name: 'Chadder',
  },
  // {
  //   centered: true,
  //   emotion: 'Intense',
  //   direction: 'left',
  //   name: 'Chadder',
  // },
  // {
  //   centered: true,
  //   emotion: 'Pride',
  //   direction: 'left',
  //   name: 'Chadder',
  // },
];

const shuffle = (unshuffled) => {
  let shuffled = unshuffled
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
  return shuffled;
};

const randomizeCarouselData = (data) => {
  const animals = {};
  const res = [];
  data.forEach((image) => {
    if (animals[image.name]) {
      animals[image.name].push(image);
    } else {
      animals[image.name] = [image];
    }
  });
  let hasValue = true;

  while (hasValue) {
    hasValue = false;
    Object.keys(animals).forEach((animal) => {
      if (animals[animal].length) {
        res.push(animals[animal].pop());
        hasValue = true;
      }
    });
  }

  return res;
};

const randomizedCarouselData = randomizeCarouselData(carouselData);

export default randomizedCarouselData;
