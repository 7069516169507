export const epilogueLetterPromptResponseOptions = [
  {
    text: 'Sure!',
    switchToEpilogueLetter: true,
  },
  {
    text: 'Nah.',
    skipEpilogueLetter: true,
    followingDialogueID: '1e806e6f-859a-4313-84b2-3903f80f4634',
  },
];
